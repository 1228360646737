* {
  background: lightgoldenrodyellow;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

a {
  text-decoration: none;
}

a:hover {
  color: gray;
}

header {
  text-align: center;
  margin-bottom: 40px;
}

h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  color: #777;
}

.main {
  text-align: center;
  margin-bottom: 40px;
}

.description {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.cta-container {
  display: flex;
  justify-content: center;
}

.year {
  color: lightgray;
}

.cta-button {
  background-color: rgb(2, 0, 128);
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 20px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: lightblue;
}

footer {
  text-align: center;
  color: #777;
}

img {
  width: 400px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.button {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  padding: 1em 2em 1em 2em;
  color: white;
  background: #ad5389;
  background: linear-gradient(0deg, rgba(20,167,62,1) 0%, rgba(102,247,113,1) 100%);
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
  background: linear-gradient(0deg, rgb(50, 92, 62) 0%, rgba(102,247,113,1) 100%);
  box-shadow: 0 0.5em 1.5em -0.5em #5db37498;
}

.button:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}

.plsx {
  width: 40px;
  background-color: black;
  margin-left: 5px;
  border-radius: 50%;
}